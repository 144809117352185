
/*
 Class to contain all of our validation messages, but also any validation functions where
 the validator npm library isn't specific enough
 */

class Validation {

    // VALIDATION ERROR MESSAGES - displayed to user when they enter incorrect data in a field

    // try keep these alphabetic to make location easier...
    static MSG_VALERR_BRAND = 'Brand is required';
    static MSG_VALERR_EMAIL = 'Please enter a valid email address consisting of a name, \'@\' and a domain, e.g. john.smith@mycompany.com.au';
    static MSG_VALERR_METER = 'Meter Number must be either 10 or 11 digits long';
    static MSG_VALERR_MODEL = 'Model is required';
    static MSG_VALERR_NAME = 'Customer name is required';
    static MSG_VALERR_PHONE = 'Home or mobile number. All digits. Starts with 02, 03, 04, 07 or 08';
    static MSG_VALERR_POSTCODE = 'Postcode must be 4 digits long';
    static MSG_VALERR_STATE = 'State must be either: NSW, VIC, QLD, SA, WA, TAS, ACT or NT';
    static MSG_VALERR_STREET = 'Street number and street name required';
    static MSG_VALERR_SUBURB = 'Suburb is required';




    // VALIDATION FUNCTIONS - created where extension on validator is required for our specific circumstances
    static isAussiePhoneNum = phone => {

        return phone.match(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/)

    }

    /*
     This just tests the string is in at least 3 parts: '23 Black St', but even '1 1 1' will pass
     */
    static isStreet = street => {

        return street.match(/^\s*\S+(?:\s+\S+){2}/)

    }

    /*
     This tests the string contains only a subset of characters - alphabetic plus a few others (space and fullstop)
     */
    static isName = name => {

        return name.match(/^[a-zA-Z .]+$/)

    }



}
export default Validation;
