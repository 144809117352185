import React from 'react';
import './DRNoConsent.css';
import Button from 'react-bootstrap/Button';
import Field from "../Auxillary/Field";

class DRNoConsentForm extends React.Component {

    state = {
        // new stuff for form field validation
        fields: {
            refusalReason: ''
        },
        fieldErrors: {},
    };


    /*
      New stuff to handle alternative form validation approach
     */
    onInputChange = ({name, value, error}) => {
        const fields = Object.assign({}, this.state.fields);
        const fieldErrors = Object.assign({}, this.state.fieldErrors);

        fields[name] = value;
        fieldErrors[name] = error;

        this.setState({fields, fieldErrors});
    };

    /*
     Adding a validate method that doesn't rely on the simplevalidator
     */
    validate = () => {
        const fields = this.state.fields;
        const fieldErrors = this.state.fieldErrors;
        const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

        if (!fields.refusalReason) return true;
        if (errMessages.length) return true;

        return false;
    };

    handleSubmit=(event)=> {
        // can't get here unless valid
        event.preventDefault();
        this.props.onNoConsentSubmit([this.state.fields.refusalReason]);
    };

    render(){
        return (
            <div>
            <h2>Consent denied.</h2>
                Please enter the reason(s) the customer refused demand-response capability consent.
                <form onSubmit={this.handleSubmit}>
                <label>Reasons</label>
                        <div className="form-row">
                            <Field
                                   name="refusalReason"
                                   controlType="textarea"
                                   className="form-control textBox"
                                   type="text"
                                   arialabel="free text reason"
                                   placeholder="Enter refusal reason"
                                   value={this.state.fields.refusalReason}
                                   onChange={this.onInputChange}
                                   validate={val => (val !== '' ? false : 'Refusal reason is required')}
                            />

                           {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>*/}
 {/*
                    <input type="text" id='reasonFreeTxt' className="form-control" placeholder={'Enter refusal reason'}
                           aria-label="free text reason"
                           onChange={this.handleFreeTxtChoicesChange}/>
                           <div className="small">
                            {this.validator.message('Refusal reason', this.state.refusalReasonsAll, 'required')}
                            </div>*/}



                        </div>


                    <div className="form-row">
                        <Button variant="danger" onClick={this.props.onCancelNoConsentSubmit}>CANCEL
                        </Button>
                <Button variant="primary"  type="submit" disabled={this.validate()}>SUBMIT
                </Button>
                    </div>
                </form>
    </div> );
}
}

export default DRNoConsentForm;
